.contactMain h1 {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: .5em;
}
table td {text-align: 'left'}
button a {color: #fff}
button a:hover {color: #fff; text-decoration: 'none';}
.todaysHours {
    background-color: #bbb;
    margin: 1%;
    padding: 4% 10% 0;
    display: inline-block;
}
.hoursTable {
    text-align: left;
    display: inline-block;
    margin: 0 auto;
}
.hoursTable td {
    padding: 1px 30px 1px 0;
}
.contactMain {
    padding: 1em;
}
.card {
    margin-bottom: 1em;
}
h2.card-header {
    font-size: 1.25em;
    font-weight: bold;
}
h3.modal-title {
    font-size: 1.25em;
    font-weight: bold;
}
.card-body h3 {
    font-size: 1.15rem;
    font-weight: bold;
}
.statusText {
    font-weight: bold;
}
.hoursSuccess {
    background-color: #d4edda;
}
.hoursSuccess .statusText {
    color: #155724;
}
.hoursWarning {
    background-color: #fff3cd;
}
.hoursWarning .statusText {
    color: #856404;
}
.hoursDanger {
    background-color: #f8d7da;
}
.hoursDanger .statusText {
    color: #721c24;
}
