.searchMain {
    padding: 2em 1.5em;
}
.searchMain h1 {
    font-size: 2em;
    font-weight: bold;
}
.searchMain .btn-full.btn.btn-primary {
    width: 100%;
    border-radius: 1px;
}
.searchMain .form-control {
    border-radius: 0;
}