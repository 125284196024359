.accountForm {
    padding: 2em 1.5em;
}
.accountForm form {
    width: 100%;
    margin-top: 1.5em;
}
.accountForm h1 {
    font-size: 2em;
    font-weight: bold;
}
.accountForm label {
    font-weight: bold;
    color: #333;
    text-transform: uppercase;
    margin-bottom: 2px;
}
.accountForm .form-control {
    border-radius: 0;
}
.accountForm .btn {
    width: 100%;
    margin-top: 1em;
    border-radius: 1px;
}
.text-muted a {
    color: inherit;
}
.text-muted.center {
    padding: 1em;
}