.app-banner {
    padding: .25em 1em;
    font-size: 90%;
    font-weight: bold;
    text-align: center;
}

.app-banner a {
    border-bottom: 1px dotted;
}

.info{
    background-color: #D9EDF7;
}
.low{
    background-color: #FFDF00;
}
.high{
    background-color: #CC0000;
    color: #FFF
}

.info a {
    color: #1E698F;
} 
.low a {
    color: #0060C8;
}
.high a {
    color: #CBE4FF;
}