.accountMain {
    text-align: left;
    padding: 1em 1em 3em;
}
.accountMain h1 {
    font-size: 1.25em;
    font-weight: bold;
    overflow-wrap: break-word;
    word-wrap: break-word;
}
.accordion {
    margin-bottom: 1.25em;
}
.accordion > .card {
    border: none;
    border-radius: 0;
}
.accountMain .accordion > .card > .card-header {
    font-size: x-large;
    background-color: #ddd;
    color: #000;
    padding: .2em 1.25em;
    margin-bottom: 0;
    border-radius: .15em .15em 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.accountMain .accordion > .card > .card-header .acctTotal {
    color: #555;
    font-size: 80%;
}
[class^="col-"]:first-child {
    padding-left: 0;
}
[class^="col-"]:last-child {
    padding-right: 0;
}
.not-expiring {
    color: #777;
}
.questions {
    margin: 1em 0;
}
.questions p {
    margin-bottom: 0;
}
.btn-full {
    width: 100%;
}
.list-group-flush > .list-group-item.dueDanger {
    border-left: #dc3545 solid 7px;
}
.list-group-flush > .list-group-item.dueWarning {
    border-left: #ffc107 solid 7px;
}
.list-group-flush > .list-group-item.dueSuccess {
    border-left: #28a745 solid 7px;
}
.fines-note {
    text-align: center;
    margin-bottom: 0;
}
.accordion .card-body, .accordion .card-footer, .accordion .list-group-flush {
    border-right: 1px solid rgba(0,0,0,.125);
    border-left: 1px solid rgba(0,0,0,.125);
}
.accordion > .card {
    border-bottom: 1px solid rgba(0,0,0,.125);
}
.accordion.card-footer:last-child {
    border-radius: 0;
}
.list-group-flush > .list-group-item.holdItem.frozen {
    background-color: #b2ffff;
    overflow: hidden;
}
.holdSnowflake {
    opacity: .5;
    color: #fff;
    position: absolute;
    right: -35px;
    bottom: -50px;
    font-size: 1200%;
    z-index: 1;
}
.list-group-flush > .list-group-item.holdItem.available {
    background-color: #b2ffb2;
    overflow: hidden;
}
.list-group-flush > .list-group-item.holdItem.available .text-success {
    font-weight: bold;
    color: #1E7B33 !important;
}
.holdBell {
    opacity: .5;
    color: #fff;
    position: absolute;
    right: -8px;
    bottom: -5px;
    font-size: 500%;
    z-index: 1;
}
.holdDiv {
    position: relative;
    z-index: 100;
}
.hold-btns {
    margin-top: 10px;
}
.bg-primary.fixed-bottom {
    color: #fff;
}
.bg-primary.fixed-bottom svg {
    margin-right: 5px;
}
.modal-content {
    width: 96%;
    border-radius: 3px;
    background-color: #fff;
    margin: 0 auto;
}
.barcode-modal .modal-content {
    border: none;
}
.item-author, .item-call-number, .pickup-location, .hold-position, .due-date, .pickup-ready {
    font-size: .9em;
}
.edit-account {
    text-align:center;
    margin: 0 auto;
    display: block;
    border: 1px solid #ccc;
    margin-top: 1px;
    border-radius: 3px;
    width: 100%;
}
.edit-account.btn.btn-default.btn-account-top {
    width: 100%;
    border: 1px solid #ccc;
  }
.btn-account-top.btn.btn-primary {
    margin-bottom: 3px;
    padding: 10px 5px;
}