.blogpostMain {
    padding: 1em;
}
.img-responsive {
    max-width: 100%;
    height: auto;
}
.blogpostMain h1 {
    font-weight: bold;
    font-size: 1.75em;
}
.blog-byline {
    color: #777;
    font-style: italic;
}