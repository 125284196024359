.eventMain {
    text-align: left;
    padding: 2em 1.5em;
}
.eventMain h1 {
    /*font-size: 2em;*/
    font-weight: bold;
}
.eventPgTitle {
    font-size: 1.5em;
}
.react-add-to-calendar__dropdown ul {
    padding-left: 5px;
}
.event-registration {
    margin-bottom: 10px;
}