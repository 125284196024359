.feedbackMain {
    padding: 2em 1.5em;
}
.feedbackMain h1 {
    font-size: 2em;
    font-weight: bold;
}
label.form-label {
    font-weight: bold;
    margin-bottom: 0;
}
textarea.form-control, input.form-control {
    border-radius: 1px;
}
.feedbackMain .btn.btn-primary {
    width: 100%;
    border-radius: 1px;
}
.v-middle {
    vertical-align: middle;
}