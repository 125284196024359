.accountForm .btn.delete-account-btn {
    width: auto;
    padding: 6px 20px;
    display: block;
    margin: 5px auto;
}
.accountForm .btn.link-danger {
    color: #dc3545;
}
.cancel-edit {
    display: block;
}
.cancel-edit .btn {
    width: auto;
    display: block;
    margin: 15px auto;
    padding: 6px 20px;
}