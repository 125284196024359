.accountsMain {
    text-align: left;
    padding: 2em 1.5em;
}
.accountsMain .card {
    border-radius: 0;
    border-right: none;
    border-left: none;
    margin-bottom: -1px;
}
.accountsArrow {
    float: right;
    font-size: 2em;
    color: #154b80;
    opacity: .75;
}
.link {
    color: #000
}
.link:hover {color: #000; text-decoration: none;}

.add-account {
    float: right;
    margin-top: -40px;
}
.add-account svg {
    margin-top: -3px;
}

.accountsMain h1, .accountsMain .h1 {
    font-size: 2em;
    font-weight: bold;
}
.text-success {
    color: #155724 !important;
}
.text-warning {
    color: #967100 !important;
}
.acct-summary {
    color: #666;
    padding-left: 10px;
    margin-top: 5px;
}