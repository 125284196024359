.homeMain {
    padding: 1em;
}
.homeMain h2 {
    font-size: 1.5em;
    font-weight: bold;
}
.homeCard.card .card-title {
    color: #fff;
    font-weight: bold;
}
.card-title svg {
    font-size: 1.5em;
}
.homeCard a:hover {
    text-decoration: none;
}
.homeCard {
    margin-bottom: 15px;
}
[class^="col-"]:first-child {
    padding-right: 7px;
}
[class^="col-"]:last-child {
    padding-left: 7px;
}