.blogpostsMain {text-align: left;}
.blogpostsDiv {font-size: x-large; background-color: #ddd;}
.blogpostsCard {
    font-weight: bold;
}
.blogpostsCard .card-body {
    padding: 1em;
}
.blogpostsCard.card {
    border-radius: 0;
    margin-bottom: -1px;
    border-right: none;
    border-left: none;
}