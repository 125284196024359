.eventsMain {
    text-align: left;
    padding: 2em 1.5em;
}
.eventsMain h1 {
    font-size: 2em;
    font-weight: bold;
}
.eventsDiv {font-size: x-large; background-color: #ddd;}
.eventsCard.card {
    text-align: left;
    border-radius: 0;
    margin-bottom: -1px;
    border-right: none;
    border-left: none;
}
.eventsCard a {color: #000}

.eventTitle {
    font-weight: bold;
}
.eventTimes {
    font-size: smaller;
}
.eventsCard .card-body {
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.eventsChevron {
    font-size: 2.5em;
    color: #154b80;
    margin-right: -20px;
    flex-shrink: 0;
}