#myicpl-dropdown {
    color: #fff;
    font-size: 1.5em;
    padding: 0;
    line-height: 1;
}
.dropdown-menu.show {
    border-radius: 1px;
    right: 0;
    left: unset;
 }
.dropdown-item a {color: #000}
.navbar-brand a {color: #fff}
.navbar-brand a:hover {color: #fff}
.navbar-dark {
    background: #154b80;
}
#myicpl-dropdown.dropdown-toggle:after {
    display: none !important;
}
#back a {
    color: #fff;
    padding-left: 0;
    font-size: .8rem;
}
.navbar-brand {
    position: absolute;
    left: calc((100vw - 111px) / 2);
}
.navbar.navbar-expand.navbar-dark {
    justify-content: space-between;
    min-height: 60px;
}
.dropdown.nav-item {
    margin-left: auto;
}
.v-middle {
    vertical-align: middle;
    font-size: 1.1em;
}
#menuBtn:focus {
    outline: none;
}