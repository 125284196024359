.settingsMain {
    text-align: left;
    padding: 2em 1.5em;
}
.settingsMain h1 {
    /*font-size: 2em;*/
    font-weight: bold;
}
.settingsPgTitle {
    font-size: 1.5em;
}
.settingsMain h2 {
    font-size: 1.5rem;
    margin-top: 40px;
    font-weight: bold;
}
.settingsMain h2.first {
    margin-top: 20px;
}

#tokenDiv {
    width: 90%;
    word-wrap: break-word;
}

.settingsMain .btn.btn-primary.btn-sm {
    margin-bottom: 10px;
    margin-right: 1%;
    width: 24%;
}
.version {
    color: #777;
}
.btn-group-toggle .btn.active.btn-primary {
    box-shadow: 3px 3px 18px 3px inset #154b80;
    border: none;
}